jQuery(document).ready(function ($) {
    function smoothScrollTo(targetClass, $) {
        if ($('.' + targetClass).length) {
            $('html, body').animate({
                scrollTop: $('.' + targetClass).first().offset().top - 260
            }, 800);
        }
    }

    $('a.menu-item').on('click', function(event) {
        var targetClass = this.hash.substring(1); // Remove the '#' from the beginning
        smoothScrollTo(targetClass, $);
    });

    // Handle the page load event with a hash in the URL
    window.onload = function() {
        if (window.location.hash) {
            var targetClassOnLoad = window.location.hash.substring(1);
            smoothScrollTo(targetClassOnLoad, $);
        }
    }

    
    

    var menu = $('.menu-anchor');
    var originalOffset = menu.offset().top;
    var existingMenuHeight =  $('#head').height() // Adjust this to the height of the existing sticky menu
    $(window).on('scroll', function() {
        if ($(window).scrollTop() >= originalOffset - existingMenuHeight) {
            menu.css({
                'position': 'fixed',
                'top': existingMenuHeight + 'px'
            });
        } else {
            menu.css({
                'position': 'static'
            });
        }
    });


});